import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { loginUser } from "../../store/actions/UserActions";
import { getSettings } from "../../store/actions/SettingActions";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: "",
        password: "",
      },
      error: "",
      settings: new Map(),
    };
    this.props.getSettings(true);
  }

  componentDidMount() {
    if (this.props.user.isAuthenticated) {
      this.props.user.user.roleId === 2
        ? this.props.history.push("/admin/dashboard")
        : this.props.history.push("/dashboard");
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user.isAuthenticated) {
      props.user.user.roleId === 2
        ? props.history.push("/admin/dashboard")
        : props.history.push("/dashboard");
    }
    if (props.error !== state.error) {
      return {
        error: props.error,
      };
    }

    return null;
  }

  onChange = (e) => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, [e.target.name]: e.target.value },
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.loginUser(this.state.form);
  };

  render() {
    const { error } = this.state;

    if (
      Object.keys(this.props.allSettings).length > 0 &&
      "status" in this.props.allSettings
    ) {
      if (
        this.props.allSettings.status &&
        this.props.allSettings.data.length > 0
      ) {
        this.setState({
          ...this.state,
          settings: new Map([
            ...this.props.allSettings.data.map((item) => [
              item.slug,
              item.value,
            ]),
          ]),
        });
        this.props.getSettings();
      }
    }

    return (
      <div className="login-bg">
        <section className="login-col">
          <div className="mid-col">
            <div className="inner-col">
              <div className="lft-col">
                <div className="info-data">
                  <div className="logo">
                    {/* <img src="images/logonew.png" alt="FlightScope" /> */}
                  </div>
                  <h2>LOGIN Your Account!</h2>
                  <p>
                    If you are already a Member, please use the form below to
                    login to the Member's Private Section.
                  </p>

                  <p>
                    If you've lost your information, please contact us so we can
                    reset your Username and/or Password.
                  </p>
                  <br />
                  <h2>You don’t have an account? </h2>
                  <Link
                    to="/sign-up"
                    className="btn custom-btn create-account orange-btn"
                  >
                    Create Account
                    <i className="fa fa-sign-in" />
                  </Link>
                  <br />
                  <br />
                  <small className="copy">
                    {" "}
                    © 2019 RPHANALYSIS.COM. All rights reserved
                  </small>
                </div>
              </div>
              <div className="lft-col login">
                <div className="info-data">
                  <div className="logo">
                    <img src="images/logonew.png" alt="RPHAnalysis" />
                  </div>
                  <h2>Create Your Account!</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur molestie tellus non urna porta interdum. Mauris
                    non purus ullamcorper, viverra metus nec, porta ligula.
                  </p>
                  <h2>Lorem ipsum dolor sit amet</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur molestie tellus non urna porta interdum. Mauris
                    non purus ullamcorper, viverra metus nec, porta ligula.
                  </p>
                  <h2>You don’t have an account? </h2>
                  <button className="btn custom-btn login-account orange-btn">
                    Login
                    <i className="fa fa-sign-in" />
                  </button>
                  <br />
                  <br />
                  <small className="copy">
                    {" "}
                    © 2019 RPHANALYSIS.COM. All rights reserved
                  </small>
                </div>
              </div>
              <div className="rgt-col SignUpBox">
                <div className="login-content ml-auto">
                  <div className="logo logo-mobile">
                    <img src="images/logonew.png" alt="RPHAnalysis" />
                  </div>
                  <h2>Login</h2>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  <div className="tab-content">
                    <div role="tabpanel" id="login">
                      <form className="row" noValidate onSubmit={this.onSubmit}>
                        <div className="form-group md-input col-sm-12">
                          <input
                            className="md-form-control"
                            required
                            type="email"
                            value={this.state.form.email}
                            onChange={this.onChange}
                            name="email"
                          />
                          <span className="highlight" />
                          <i
                            className="fa fa-envelope custome-envelope"
                            aria-hidden="true"
                          />
                          <label>Email Address</label>
                        </div>
                        <div className="form-group md-input col-sm-12">
                          <input
                            className="md-form-control"
                            required
                            type="password"
                            name="password"
                            value={this.state.form.password}
                            onChange={this.onChange}
                          />
                          <span className="highlight" />
                          <i className="fa fa-key custome-key" />
                          <label>Password</label>
                        </div>

                        <div className="password-col col-sm-6">
                          <span className="forgot-password">
                            <Link to="/forgot-password">Forgot Password?</Link>
                          </span>
                        </div>

                        <div className="login-btm col-md-6 col-sm-12">
                          <button type="submit" className="btn custom-btn">
                            Login
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="for-small-device">
                      <h2>You don’t have an account? </h2>
                      <a
                        href="/sign-up"
                        className="btn custom-btn create-account"
                      >
                        Create Account
                        <i className="fa fa-sign-in" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="social-links">
                  <h2> Contact with us: </h2>
                  <a
                    href={
                      this.state.settings.get("facebook_link") &&
                      this.state.settings.get("facebook_link") !== null
                        ? this.state.settings.get("facebook_link")
                        : "https://www.facebook.com/resultsdepartment/"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    title={
                      this.state.settings.get("facebook_link")
                        ? this.state.settings
                            .get("facebook_link")
                            .split("/")
                            .filter(Boolean)
                            .pop()
                        : "resultsdepartment"
                    }
                  >
                    <span className="box-social-links face">
                      <i className="fa fa-facebook-f" />
                    </span>
                  </a>

                  <a
                    href={
                      this.state.settings.get("instagram_link") &&
                      this.state.settings.get("instagram_link") !== null
                        ? this.state.settings.get("instagram_link")
                        : "https://www.instagram.com/peakathletics_ashburn/"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    title={
                      this.state.settings.get("instagram_link")
                        ? this.state.settings
                            .get("instagram_link")
                            .split("/")
                            .filter(Boolean)
                            .pop()
                        : "peakathletics_ashburn"
                    }
                  >
                    <span className="box-social-links linked">
                      <i className="fa fa-instagram" />
                    </span>
                  </a>
                </div>
                {/* Tab panes */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  error: state.error,
  allSettings: state.setting.settings,
});

export default connect(mapStateToProps, { loginUser, getSettings })(Login);
