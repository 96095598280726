import React, { Component } from "react";
import Alert from "react-s-alert";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Cleave from "cleave.js/react";

import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";

import TopHeader from "../layout/header";

import { API_URL } from "../../constants/config";

class Enquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
        followUp: true,
      },
      error: "",
      captchaValidated: false,
    };
  }

  componentDidMount() {
    // this.props.getSurveys();
  }

  onChange = (e) => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, [e.target.name]: e.target.value },
    });
  };

  onCaptchaValidate = () => {
    this.setState({ captchaValidated: true });
  };

  onOptionChange = (e, value) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [e.target.name]: value,
      },
    });
  };

  onSubmit = async (e, id) => {
    e.preventDefault();

    if (!this.state.captchaValidated) {
      return this.setState({ error: "Please pass the captcha first" });
    }

    axios
      .post(API_URL + "public/contact_us", this.state.form)
      .then((res) => {
        this.setState({ error: "" }, () =>
          Alert.success(res.data.message, {
            position: "top-right",
            effect: "bouncyflip",
            timeout: 2000,
          })
        );
        this.setState({
          form: {
            name: "",
            email: "",
            phone: "",
            message: "",
            followUp: true,
          },
        });
      })
      .catch((err) => this.setState({ error: err.response.data.message }));
  };

  render() {
    const { error } = this.state;
    return (
      <>
        <TopHeader />

        <div className="container">
          {/* <h6 className="question-heading">Add Learning Content - Contact us</h6> */}
          <div className="category-block">
            <div className="row">
              <div className="col-md-12 text-center mb-4">
                <h1 className=" font-weight-bold">Get in Touch</h1>
              </div>
              <div className="col-md-7">
                <div className="contact-wrapper">
                  <h3 className="mb-4 font-weight-bold">
                    Contact a Team Member
                  </h3>
                  <p className="mb-4">
                    We'd love to hear from you. Your questions &amp; concerns
                    are of the utmost importance to our success. Please use the
                    form to the right to request additional information about
                    our course, services, and promotions, and feel free to let
                    us know how we're doing in our efforts to provide the best
                    possible experience for you and your guests.
                  </p>
                  <hr className="mb-4" />
                  <div className="mb-4">
                    <address>
                      Peak Athletics Ashburn
                      <br />
                      info@peakathleticsashburn.com
                      <br />
                      21720 Red Rum Dr,
                      <br />
                      Suite 187, Ashburn, VA 20147
                    </address>
                  </div>
                  <p>
                    Click on a contact below for more info and an email contact
                    from:
                  </p>
                </div>
              </div>
              <div className="col-md-4 offset-lg-1">
                <div className="row">
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  <form onSubmit={this.onSubmit} noValidate>
                    <div className="form-group md-input col-sm-12">
                      <input
                        className="md-form-control"
                        name="name"
                        value={this.state.form.name}
                        onChange={this.onChange}
                        required
                        type="text"
                      />
                      <span className="highlight" />
                      <label>Name</label>
                    </div>
                    <div className="form-group md-input col-sm-12">
                      <input
                        className="md-form-control"
                        name="email"
                        required
                        type="email"
                        value={this.state.form.email}
                        onChange={this.onChange}
                      />
                      <span className="highlight" />
                      <label>Email</label>
                    </div>
                    <div className="form-group md-input col-sm-12">
                      <Cleave
                        name="phone"
                        data-state="phone"
                        className="md-form-control"
                        placeholder="Phone Number"
                        value={this.state.form.phone}
                        options={{
                          delimiter: "-",
                          blocks: [3, 3, 4],
                        }}
                        onChange={this.onChange}
                      />
                      <span className="highlight" />
                      <label>Phone</label>
                    </div>
                    <div className="form-group md-input col-sm-12">
                      <textarea
                        name="message"
                        className="md-form-control"
                        placeholder="Enter a Message"
                        value={this.state.form.message}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="form-group md-input col-sm-12">
                      Would you like a follow-up?
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio1"
                          name="followUp"
                          className="custom-control-input"
                          checked={this.state.form.followUp}
                          onChange={(e) => this.onOptionChange(e, true)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio1"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio2"
                          name="followUp"
                          className="custom-control-input"
                          checked={!this.state.form.followUp}
                          onChange={(e) => this.onOptionChange(e, false)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio2"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    <div className="form-group md-input col-sm-12 text-center">
                      <ReCAPTCHA
                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                        onChange={this.onCaptchaValidate}
                      />
                    </div>
                    <div className="form-group md-input col-sm-12 d-flex justify-content-around">
                      <button type="submit" className="btn custom-btn ">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Alert stack={{ limit: 1 }} />
      </>
    );
  }
}

export default Enquiry;
