import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ExportToCsv } from "export-to-csv";
import TopHeader from "../layout/header";
import {
  getUser,
  getUsers,
  updateFetchedUser,
  emptyFetchedUser,
} from "../../store/actions/UserActions";

import isEmpty from "../../validation/is-empty";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      showModal: false,
    };
  }

  componentDidMount() {
    this.props.getUsers();
  }

  updateUserStatus = (userId, status) => {
    this.props.updateFetchedUser({ isActive: status }, userId);
  };

  fetchUser = (id) => this.props.getUser(id);

  handleExportList = (users) => {
    if (!isEmpty(users)) {
      const newUsers = users.map((val) => ({
        Firstname: val.firstName || "N/A",
        Lastname: val.lastName || "N/A",
        Email: val.email || "N/A",
        Phone: val.phone || "N/A",
      }));

      const options = {
        fieldSeparator: ",",
        filename: "Survey-Users-List",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        title: "Survey Application Users",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: ["Firstname", "Lastname", "Email", "Phone"],
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(newUsers);
    }
  };

  userProfileModal = (user = this.props.fetchedUser) => {
    return (
      <div className="modal fade show" id="myModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="question-heading">User Details</h6>
              <button
                type="button"
                className="close"
                onClick={this.props.emptyFetchedUser}
              >
                &times;
              </button>
            </div>

            <div className="modal-body">
              <div className="row">
                <table className="table table-striped">
                  <thead />
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>
                        {user.firstName} {user.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{user.email}</td>
                    </tr>

                    <tr>
                      <td>Phone</td>
                      <td>{user.phone}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{user.address}</td>
                    </tr>
                    <tr>
                      <td>City</td>
                      <td>{user.city}</td>
                    </tr>
                    <tr>
                      <td>State</td>
                      <td>{user.state}</td>
                    </tr>
                    <tr>
                      <td>Country</td>
                      <td>{user.country}</td>
                    </tr>
                    <tr>
                      <td>Zip</td>
                      <td>{user.postalCode}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderUser = (user) => {
    // console.log('f', user);
    return (
      <tr key={user.id}>
        <td>{user.firstName + " " + user.lastName}</td>
        <td>{user.email}</td>
        <td>{user.phone}</td>
        <td>
          <div className="category-icons">
            <span className="group" onClick={() => this.fetchUser(user.id)}>
              <i className="fa fa-eye" />
            </span>
            <span
              className={user.isActive ? "group" : "delete"}
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Change Status"
              onClick={(e) => this.updateUserStatus(user.id, !user.isActive)}
            >
              <i
                className={`fa ${
                  user.isActive ? "fa-toggle-on" : "fa-toggle-off"
                }`}
              />
            </span>
          </div>
        </td>
      </tr>
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (props.success !== state.success) {
      return {
        success: props.success,
      };
    }
    if (props.error !== state.error) {
      return {
        error: props.error,
      };
    }
    return null;
  }

  render() {
    return (
      <>
        <TopHeader />
        <div className="wrapper">
          <div className="container">
            <div className="category-block">
              {this.state.success > 0 && (
                <div className="col-xs-12 col-md-12 mb-10 text-center alert alert-success">
                  <span>User status has been changed.</span>
                </div>
              )}

              {this.state.error && (
                <div className="col-xs-12 col-md-12 mb-10 text-center alert alert-danger">
                  <span>{this.props.error}</span>
                </div>
              )}
              <div className="row">
                <div className="col-sm-10">
                  <h1 className="main-heading">User List</h1>
                </div>
                <div className="col-sm-2">
                  <button
                    onClick={() => this.handleExportList(this.props.users)}
                  >
                    Export <i className="fa fa-download" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="row">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th width="30%">User Name</th>
                      <th width="30%">Email Address</th>
                      <th width="25%">Phone Number</th>
                      <th width="15%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(this.props.users) &&
                      this.props.users.map((user) => this.renderUser(user))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {!isEmpty(this.props.fetchedUser) && this.userProfileModal()}
      </>
    );
  }
}

UserList.propTypes = {
  getUsers: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  fetchedUser: PropTypes.object.isRequired,
  updateFetchedUser: PropTypes.func.isRequired,
  emptyFetchedUser: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error,
  success: state.success,
  fetchedUser: state.user.fetchedUser,
  users: state.user.users,
});

export default connect(mapStateToProps, {
  getUsers,
  getUser,
  updateFetchedUser,
  emptyFetchedUser,
})(UserList);
