import React, { Component } from "react";
import { connect } from "react-redux";

import { getUserFeedbacksBySurvey } from "../../store/actions/UserActions";
import TopHeader from "../layout/header";
import UserFeedback from "../user/UserFeedback";
import isEmpty from "../../validation/is-empty";

class EnrolledUsers extends Component {
  state = {
    showModal: false,
    feedback: {},
    assessmentId: "",
    userName: "",
  };

  componentDidMount() {
    this.props.getUserFeedbacksBySurvey(this.props.match.params.surveyId);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.feedback !== state.feedback) {
      return {
        feedback: props.feedback,
      };
    }

    return null;
  }

  showModal = (e, assessmentId, userName) => {
    // this.props.getUserFeedbacks(id);

    this.setState({
      showModal: true,
      assessmentId,
      userName,
    });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  formatDate(date) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    return day + " " + monthNames[monthIndex] + " " + year;
  }

  renderModal = () => {
    return (
      <div className="modal fade show" id="myModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="question-heading">
                {this.state.userName}: Survey Feedback
              </h6>
              <button type="button" className="close" onClick={this.hideModal}>
                &times;
              </button>
            </div>

            <div className="modal-body">
              {this.props.feedbacks.length && (
                <>
                  <br />
                  <div className="modal-question-header">
                    <div className="magin-15">
                      <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-8 answer-heading">
                          Answer
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-4 answer-heading">
                          Count
                        </div>
                      </div>
                    </div>
                    {JSON.parse(
                      this.props.feedbacks[this.state.assessmentId].answersCount
                    ).map((answer, index) => (
                      <UserFeedback
                        key={index}
                        first={answer.option}
                        second={answer.optionCount}
                      />
                    ))}
                  </div>
                  <hr />
                  <div className="question">
                    <div className="magin-15">
                      <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-8 answer-heading">
                          Question
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-4 answer-heading">
                          Answer
                        </div>
                      </div>
                    </div>

                    {JSON.parse(
                      this.props.feedbacks[this.state.assessmentId]
                        .answersAttempt
                    ).map((answer, index) => (
                      <UserFeedback
                        key={index}
                        first={answer.question}
                        second={answer.option}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderEnrolledUserList = (user, assessmentId) => {
    return (
      <tr key={user.id}>
        <td>
          {user.firstName} {user.lastName}
        </td>
        <td>
          {this.formatDate(
            new Date(this.props.feedbacks[assessmentId].createdAt)
          )}
        </td>
        <td>
          <div className="action-btn">
            <span
              className="edit fa fa-eye"
              onClick={(e) =>
                this.showModal(
                  e,
                  assessmentId,
                  user.firstName + " " + user.lastName
                )
              }
            />
          </div>
        </td>
      </tr>
    );
  };

  render() {
    const { feedbacks } = this.props;
    return (
      <>
        <TopHeader />

        <div className="wrapper">
          <div className="container">
            <div className="category-block">
              <h1 className="main-heading">
                Enrolled User List -{" "}
                {!isEmpty(feedbacks) && feedbacks[0].Test.name}
              </h1>

              <div className="row">
                <table className="table table-striped table-responsive">
                  <thead>
                    <tr>
                      <th>User name</th>
                      <th>Enrollment Date</th>
                      <th width="10%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(feedbacks) &&
                      feedbacks.map((feedbackObj, index) => {
                        if (feedbackObj.User) {
                          return this.renderEnrolledUserList(
                            feedbackObj.User,
                            index
                          );
                        }
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {this.state.showModal === true && this.renderModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  feedbacks: state.user.feedbacks,
});

export default connect(mapStateToProps, {
  getUserFeedbacksBySurvey,
})(EnrolledUsers);
